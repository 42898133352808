<template>
	<section id="portfolio" class="single-useful">
		<b-row>
		    <b-col md="12">
		    	<ul class="breadcrumb size-24 mb-2 pl-0">
		    		<li class="breadcrumb-item" @click="parentLink">
		    			<span class="cursor-pointer" style="color: #000000CC ">Useful Information</span>
		    		</li>
		    		<li class="breadcrumb-item" aria-current="page"><strong>{{ detail.title }}</strong></li>
		    	</ul>		    	
		    </b-col>
		</b-row>
		<b-row class="detail-item pb-lg-3 pb-2">
		    <b-col md="12">  
		    	<template>
		    		<div id="imgSmall" class="imgDisplayNone">
		    			<div class="text-center mb-1">
			    			<b-img v-if="detail.image" :src="detail.image"></b-img>
			    		</div>	
		    		</div>		    		
		    		<div id="imgBig" class="imgDisplayNone">
		    			<div class="bg-df" :style="`background-image: url(`+ renderLinkImg(detail.image) + `)`">
			    			<b-img :src="require('@/assets/images/itees/participant-portal/Useful-detail.png')" class="w-100 opacity"></b-img>
			    		</div>  
			    	</div>
		    		<div class="item">
		    			<div class="info black-child">
			    			<h2>{{ detail.title }}</h2>
			    			<div class="size-18">{{ detail.description }}</div>
			    		</div>
			    	</div>
			    	<div class="frame">

			    		<template v-for="(item, index) in files">
			    			<b-card class="file-list blue-item-hover" :key="item.index" v-if="item.path">
					    		<div class="title black">{{ item.title }}</div>
					          	<div class="cursor-pointer black black-ico" @click="downloadAction(item.path, item.title); ">
								    <feather-icon
							            icon="DownloadIcon"
							            size="24"
							          />
								</div>
					    	</b-card>
			    		</template>			    		

			    	</div>			    	
		    	</template>
		    </b-col>
		</b-row>
	</section>	
 
</template>

<script>

	export default {
	  	data() {
		    return {
		    	detail: [],
		        files:[],
		        widthImg: null,
		        heightImg: null
		    }
		},
		created(){
			this.fetchUserfulDetail()			
		},
		methods: {

			downloadAction(path, title){
		    	const url = `${process.env.VUE_APP_SERVER_URL}/api/downLoad?url_file=${path}`
		  		axios({
		        	method: 'get',
		        	url: url,
		        	responseType: 'blob',
		      	})
		      	.then(response => {		        
		        	this.forceFileDownload(response, title)	           
		      	})
		      	.catch(() => console.log('error occured'))
		    },
		    forceFileDownload(response, namFile){
		      	const url = window.URL.createObjectURL(new Blob([response.data]))
		      	const link = document.createElement('a')
		      	link.href = url
		      	link.setAttribute('download', namFile)
		      	document.body.appendChild(link)
		      	link.click()
		    },
		  	fetchUserfulDetail(){
				this.$store
				.dispatch('auth/fetchUserfulDetail', {
					id: this.$route.params.id	
				})
				.then(response => {

					this.detail = response.data.data;
					this.files = response.data.data.downloadable

					this.getSizeImg(this.detail.image)
					
				})
				.catch((error) => {
					console.log(error)
				})
			},
			parentLink(){
				this.$router.push(`/participant/useful-information`);
			},
			getSizeImg(imgSrc){
				var newImg = new Image();
			    newImg.src = imgSrc;
			    $(newImg).on('load', function(){			    	
			    	this.widthImg = newImg.width
			    	this.heightImg = newImg.height

			    	if(this.widthImg && parseInt(this.widthImg) >= 1000){
				    	document.getElementById('imgBig').style.display = 'block';
				    }
				    else{
				    	document.getElementById('imgSmall').style.display = 'block';
				    }
			    });
			},
			renderLinkImg(img) {
				return `${img}`
			}
		}
	};	

</script>

<style lang="scss">	

	@media only screen and (max-width: 1400px){
		.size-18{
			font-size: 15px !important;
		}
	}
	@media only screen and (max-width: 480px){
		.size-18{
			font-size: 14px !important; 
		}
	}
</style>

